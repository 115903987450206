// NavBar is hidden in that <TopBarComponent />
// useHideChat() is used to hide the chat

import { AuthGradient } from 'components/common/AuthDialog/AuthGradient';
import { ForgotPasswordDialog } from 'components/common/AuthDialog/ForgotPasswordDialog';
import { LoginDialog } from 'components/common/AuthDialog/LoginDialog';
import { SignUpDialog } from 'components/common/AuthDialog/SignUpDialog';
import { SignUpWithEmailDialog } from 'components/common/AuthDialog/SignUpWithEmailDialog';
import { Button } from 'components/common/buttons/Button';
import { Paragraph } from 'components/typography';
import { phrases } from 'constants/phrases';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

type FooterLink = {
  title: string;
  url: string;
};
const footerLinks: FooterLink[] = [
  { title: 'About', url: 'https://hiber3d.com/about' },
  { title: 'FAQ', url: 'https://hiber3d.com/faq' },
  { title: 'Help', url: '/contact' },
  { title: 'Press', url: 'https://hiber3d.com/news' },
  { title: 'Content Creator Policy', url: 'https://hiber3d.com/contentcreatorpolicy' },
  { title: 'Terms of Use', url: 'https://hiber3d.com/terms-of-use' },
  { title: 'Privacy Policy', url: 'https://hiber3d.com/privacy-policy' },
  { title: 'Cookie Policy', url: '/cookies?hideCookieBanner=true' },
];

const FooterLink = ({ title, url }: FooterLink) => {
  return (
    <a href={url} target="_blank">
      {title}
    </a>
  );
};

export const LoginWall = () => {
  const [type, setType] = useState('SIGN_UP');
  const router = useRouter();

  const onSignUp = async () => {
    router.push('/');
  };
  return (
    <div style={{ backgroundImage: 'url(/images/login-background.webp' }} className="p-0 h-dvh bg-cover ">
      <div
        style={{ background: 'linear-gradient(rgba(41, 42, 49, 0.7), rgba(41, 42, 49, 0.2))' }}
        className="w-full h-dvh flex px-2">
        {type === 'SIGN_UP' && (
          <div className="absolute top-4 right-4  medium:block ">
            <Button size="medium" variety="primary" className="px-10" onClick={() => setType('LOGIN')}>
              <span className="px-8">Log In</span>
            </Button>
          </div>
        )}
        <div className="flex justify-center items-center flex-col w-full h-full">
          {type !== 'SIGN_UP_EMAIL' && (
            <Image
              className="w-auto medium:block hidden items-self-start mt-8 absolute top-8"
              alt="logo"
              priority={true}
              src="https://cdn.hibervr.com/static/icons/HW+logo+BETA.svg"
              height={63}
              width={393}
            />
          )}
          <div className="max-w-[550px] drop-shadow-[2px_2px_30px_rgba(0,0,0,0.91)] ">
            <AuthGradient>
              {type === 'SIGN_UP' && (
                <SignUpDialog
                  title={phrases.authDialog.signUp.loginWall}
                  navigateToLogin={() => {}}
                  navigateToSignupWithEmail={() => setType('SIGN_UP_EMAIL')}
                  navigateToUsername={() => {}}
                  onClose={() => {}}
                  hideLoginOption
                  hideParagraph
                  onSignUpCompleted={onSignUp}
                />
              )}
              {type === 'SIGN_UP_EMAIL' && (
                <SignUpWithEmailDialog
                  navigateToLogin={() => setType('LOGIN')}
                  navigateToUsername={() => setType('USERNAME')}
                  onSignUpPage={true}
                  onClose={() => {}}
                  onSignUpCompleted={onSignUp}
                />
              )}
              {type === 'LOGIN' && (
                <LoginDialog
                  navigateToSignUp={() => setType('SIGN_UP')}
                  navigateToForgotPassword={() => setType('FORGOT_PASSWORD')}
                  navigateToUsername={() => setType('USERNAME')}
                  onClose={() => {}}
                  onLoginCompleted={async () => {
                    router.push('/');
                  }}
                />
              )}
              {type === 'FORGOT_PASSWORD' && (
                <ForgotPasswordDialog navigateToLogin={() => setType('LOGIN')} onClose={() => {}} />
              )}
            </AuthGradient>
          </div>
        </div>
      </div>
      <footer className="bg-greyscale-almostBlack w-full h-[250px]">
        <div className="flex justify-center py-6 gap-8 flex-wrap">
          {footerLinks.map(link => (
            <FooterLink {...link} key={link.title} />
          ))}
        </div>
        <div className="flex justify-center">
          <div
            style={{ width: 'min(1250px, 95%)' }}
            className="border-transparent border-t-[1px]  border-solid border-t-greyscale-dark flex justify-center">
            <Paragraph size="small" className="text-greyscale-lightGrey mt-6">
              © {new Date().getFullYear()} HiberWorld
            </Paragraph>
          </div>
        </div>
      </footer>
    </div>
  );
};
