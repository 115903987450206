import { useMe } from 'hooks/useMe';
import { MainLayout } from 'views/global/MainLayout';
import { ModuleStartPage } from 'views/pages/start';
import { LoginWall } from 'views/pages/start/LoginWall/LoginWall';

export const DAO_FEED_DESCRIPTION = 'Be among the first to create your own game with a brand new aesthetic!';
export const DAO_FEED_TITLE = 'Latest Worlds';

const FeedPage = () => {
  const { isLoggedIn } = useMe();
  if (!isLoggedIn) {
    return <LoginWall />;
  }
  return (
    <MainLayout fullWidth noHorizontalPadding>
      <ModuleStartPage />
    </MainLayout>
  );
};

export default FeedPage;
